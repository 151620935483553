<template>
  <div>
    <div v-if="!userloading && user.login" class="basic">
      <v-tabs v-model="tab" :vertical="!mobile" show-arrows
        class="fixed" background-color="#141E30" color="white" style=" min-height:calc(100vh - 48px)">
        <v-tab key="settings" to="#settings">기본</v-tab>
        <v-tab key="custom" to="#custom">커스텀</v-tab>
        <v-tab key="purchase" to="#purchase">구매내역</v-tab>
        <v-tab key="calendar" to="#calendar">달력보기</v-tab>
        <v-tab key="bugreport" to="#bugreport">오류신고</v-tab>
        <v-tab key="fund" to="#fund">펀딩</v-tab>

        <v-tabs-items touchless v-model="tab">

          <v-tab-item
            id="settings"
            key="settings"
            :transition="false"
            :reverse-transition="false">
            <v-row no-gutters align="center" justify="center" >
              <v-col cols="12">
                <v-card class="pa-4 ma-3 text-left" flat>
                  <v-row dense>
                    <v-col md="2" cols="12" >
                      <div class="headline font-weight-bold pa-1 pb-0">계정</div>
                      <div class="subtitle caption pa-1">
                        <div class="desc_title">
                          기본 정보 표시
                        </div>
                      </div>
                    </v-col>
                    <v-col md="8" cols="12" >
                      <v-row dense class="mt-2">
                        <v-col md="6" cols="12">
                          <v-form v-model="valid" ref="form" lazy-validation>
                            <v-text-field
                              dense
                              v-model="name"
                              class="right-input"
                              label="이름"
                              prepend-icon="tag"
                              required />
                            <v-text-field
                              dense
                              v-model="email"
                              class="right-input"
                              label="이메일"
                              prepend-icon="email"
                              readonly
                              required />
                            <v-text-field
                              dense
                              v-model="last_action_date"
                              class="right-input"
                              label="마지막 접속일"
                              prepend-icon="login"
                              readonly
                              required />
                          </v-form>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4" class="subtitle-2 big pa-4" align="center" >
                          <v-text-field
                            dense
                            v-model.number="height_init"
                            class="center-input mt-4 narrow"
                            label="키"
                            :type="mobile?'number':''"
                            required
                            hint="cm"
                            persistent-hint
                            v-on:change="changeHeight" />
                        </v-col>

                        <v-col md="3" cols="6" class="subtitle-2  pa-4" align="center" >
                          <div class="info_title">성별</div>
                          <div class="recommended">{{user.sex=='F'?'여성':'남성'}}</div>
                          <div class="unit"></div>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="8">
                          이벤트, 프로모션 알림 메일 및 SMS 수신
                        </v-col>
                        <v-col cols="2">
                          <v-checkbox v-model="event_sms" @change="changeEvent" />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col md="2" cols="12" >
                      <v-btn
                        color="red"
                        dark
                        block
                        @click="deleteaccount"
                        >회원탈퇴</v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <v-row no-gutters align="center" justify="center" >
              <v-col cols="12">
                <v-card class="pa-4 ma-3 text-left" flat>
                  <v-row dense>
                    <v-col md="2" cols="12" >
                      <div class="headline font-weight-bold pa-1 pb-0">초기화</div>
                      <div class="subtitle caption pa-1">
                        <div class="desc_title">
                          데이터 초기화<br/>
                          템플릿은 삭제되지 않습니다.
                        </div>
                      </div>
                    </v-col>
                    <v-col md="8" cols="12" >
                      <v-row dense class="mt-2">
                        <v-col md="6" cols="12">

                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col md="2" cols="12" >
                      <v-btn
                        color="red"
                        dark
                        block
                        @click="delete_data"
                        >초기화</v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

          </v-tab-item>

          <v-tab-item
            id="custom"
            key="custom"
            :transition="false"
            :reverse-transition="false">
            <Custom />
          </v-tab-item>

          <v-tab-item
            id="purchase"
            key="purchase"
            :transition="false"
            :reverse-transition="false">
            <Purchase_info />
          </v-tab-item>

          <v-tab-item
            id="calendar"
            key="calendar"
            :transition="false"
            :reverse-transition="false">
            <FoodHistory />
          </v-tab-item>

          <v-tab-item
            id="bugreport"
            key="bugreport"
            :transition="false"
            :reverse-transition="false">
            <Bugreport />
          </v-tab-item>

          <v-tab-item
            id="fund"
            key="fund"
            :transition="false"
            :reverse-transition="false">
            <Fund />
          </v-tab-item>

        </v-tabs-items>

      </v-tabs>

      <div style="position:absolute; bottom:0; right:0; font-size:9px; ">
        본 사이트는 (재)고양지식정보산업진흥원의 지원을 받아 제작 되었습니다.
      </div>

    </div>

    <div v-if="!userloading && !user.login" >
      <NeedLogin />
    </div>
    <div v-if="userloading">
      <UserLoading />
    </div>
  </div>

</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import { mapGetters } from 'vuex'

import Purchase_info from './Purchase_info';
import FoodHistory from './FoodHistory';
import Bugreport from './Bugreport';
import Custom from './Custom';
import Fund from './Fund';

import NeedLogin from './NeedLogin';
import UserLoading from './UserLoading';

export default {
  components: {
    NeedLogin,
    UserLoading,
    Purchase_info,
    FoodHistory,
    Bugreport,
    Custom,
    Fund,
  },
  props: {
    msg: String
  },
  data: () => {
    return {
      tab: "settings",
      loading: true,
      basebpm: 65,
      avgbpm: 100,
      wotime: 1,
      woname: "",
      foodname: "",
      foodkcal: 0,
      foodcarb: 0,
      foodprotein: 0,
      foodfat: 0,
      foodtype: 1,
      activity: [],
      food_list: [],
      food_types: [
        { text: '일반', value: 1, },
        { text: '보충제', value: 2, },
      ],
      valid: false,
      name: "",
      email: "",
      last_action_date: "",
      isgoogle: "",
      password: "",
      passRules: [
        v => v.length >= 8 || '최소 8글자를 입력하세요.',
      ],
      height_init:0,
      event_sms: false,
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      data: "data",
      mobile: "mobile",
      userloading: "userloading",

    })
  },
  mounted: async function() {
    this.$nextTick(async function() {
      await this.reload_info();
    });
  },
  methods: {
    reload_info: async function() {
      if ( firebase.auth().currentUser) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        const user = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/one', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        this.height_init = user.data.height;

        this.email = user.data.email;
        this.name = user.data.name;
        this.basebpm = user.data.bpm;

        this.last_action_date = user.data.last_action_date;

        this.event_sms = user.data.agreement_event;

        this.loading = false;
      } else {
        setTimeout(() => {
          this.reload_info();
        }, 500);
      }
    },
    deleteaccount: async function() {
      if ( !confirm('계정을 삭제하시겠습니까?') ) return;
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.delete(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/', {
          data: {
            email: this.user.data.email,
          },
          headers: {'id_token': id_token},
      });
      const user = firebase.auth().currentUser;
      user.delete().then(function() {
        this.$router.push({ path:"/main" });
      }).catch(function(error) {
        console.log(error);
      });
    },
    delete_data: async function() {
      if ( !confirm('데이터를 초기화하시겠습니까?') ) return;
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.delete(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/data/', {
          data: {
            email: this.user.data.email,
          },
          headers: {'id_token': id_token},
      });
    },
    changeHeight: async function() {
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/height/', {
        email: this.user.data.email,
        height: this.height_init,
      }, {
        headers: {'id_token': id_token},
      });

      await this.$store.dispatch("postHeight", this.height_init);

    },
    changeEvent: async function(e) {
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/agreement/event', {
        email: this.user.data.email,
        agreement_event: e,
      }, {
        headers: {'id_token': id_token},
      });
      this.event_sms = e;
    }
  }
}
</script>

<style scoped>
</style>
