<template>
  <div>
    <div v-if="!userloading && user.login" class="basic">

      <v-row dense v-if="!loaded">
        <v-container fluid class="ma-10">
          <v-row dense>
            <v-col cols="12">
              <v-row class="align-center justify-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-col cols="12" align=center>
                  기본 정보를 불러오고 있습니다.
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-row>

      <v-row no-gutters v-if="loaded" align="center" justify="center" >
        <v-col cols="12">
          <v-card class="pa-4 ma-3 text-left" flat>
            <v-row dense>
              <v-col md="3" cols="3">
                <div class="headline font-weight-bold pa-1 pb-0">커스텀 활동</div>
              </v-col>
              <v-col md="9" cols="9">
                <div class="subtitle pt-2">
                  <div class="desc_title">
                    심박계 필수(오차주의), 활동관리에서 검색 가능
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="pa-4 ma-3 text-left" flat>
            <v-row dense>
              <v-col cols="3" >

                <v-row dense>
                  <v-col cols="12" class="title mb-5 font-weight-bold">
                    기본 정보 입력<br/>
                    <span class="body-2"></span><br/>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" class="subtitle-2 big pa-2 ma-0"  >
                    <v-text-field
                      dense
                      v-model.number="basebpm"
                      class="center-input"
                      label="휴식시 심박수"
                      required
                      hint="bpm"
                      persistent-hint
                      v-on:change="changeBaseBPM" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="9">

                <v-row dense>
                  <v-col cols="12" class="title mb-5 font-weight-bold">
                    커스텀 활동 입력<br/>
                    <span class="body-2">PAR는 1시간 기준으로 계산됩니다. 입력 후, 활동관리에서 시간을 입력해 주세요.</span><br/>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col md="3" cols="12" class="subtitle-2 big pa-2 ma-0">
                    <v-text-field
                      dense
                      v-model="woname"
                      class="center-input"
                      label="운동 이름"
                      placeholder="운동 이름"
                      required />
                  </v-col>

                  <v-col md="3" cols="6"  class="subtitle-2 big pa-2 ma-0">

                    <v-text-field
                      dense
                      v-model.number="avgbpm"
                      class="center-input"
                      label="평균 심박수"
                      required
                      hint="bpm"
                      persistent-hint />
                  </v-col>

                  <v-col md="3" cols="6"  class="subtitle-2 big pa-2 ma-0">

                    <v-text-field
                      dense
                      v-model.number="wotime"
                      class="center-input"
                      label="운동 시간"
                      required
                      hint="hours"
                      persistent-hint />
                  </v-col>

                  <v-col cols="3"  class="subtitle-2 big pa-1 ma-0">
                    <v-btn color="#FBDE44FF" class="elevation-0" @click="addCustomActivity" block height="100%">
                      추가
                    </v-btn>
                  </v-col>

                </v-row>

              </v-col>
            </v-row>
          </v-card>

          <v-card class="pa-4 ma-3 text-left" flat>
            <v-row>
              <v-col md="12" cols="12">
                <v-container fluid v-if="loading" class="ma-10">
                  <v-row dense>
                    <v-col cols="12">
                      <v-row class="align-center justify-center">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                        <v-col cols="12" align=center>
                          커스텀 활동을 불러오고 있습니다.
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
                <v-row dense>
                  <v-col cols="12" class="title font-weight-bold">
                    커스텀 활동 목록
                  </v-col>
                </v-row>
                <v-row dense >
                  <v-col md="4" cols="12" v-for="(each, i) in activity" :key="i">
                    <v-card outlined class="elevation-0 pa-2 ma-0">
                      <v-row dense>
                        <v-col cols="5" class="subtitle-2">
                          {{ each.name }}
                        </v-col>
                        <v-col cols="2" align="right" class="caption" style="border-bottom: 1px solid #4472C7">{{ each.time }} hr</v-col>
                        <v-col cols="2" align="right" class="caption" style="border-bottom: 1px solid #70AD47">{{ each.par }}</v-col>
                        <v-col cols="3" align="right">
                          <v-btn xSmall color="#FBDE44FF" height="100%" class="elevation-0 op_btn" @click="deleteCustomActivity(i, each._id)" >
                            <v-icon color="black" small>close</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>

            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters v-if="loaded"  align="center" justify="center" >
        <v-col cols="12">
          <v-card class="pa-4 ma-3 text-left" flat>
            <v-row dense>
              <v-col md="3" cols="3">
                <div class="headline font-weight-bold pa-1 pb-0">커스텀 음식</div>
              </v-col>
              <v-col md="9" cols="9">
                <div class="subtitle pt-2">
                  <div class="desc_title">
                    식단관리에서 검색 가능
                  </div>
                </div>
              </v-col>
            </v-row>


          </v-card>
          <v-card class="pa-4 ma-3  text-left" flat>

            <v-row dense>
              <v-col cols="12" class="title mb-0 font-weight-bold">
                커스텀 음식 입력
              </v-col>
            </v-row>

                <v-card flat class="pa-4 ma-3">
                  칼로리 계산은 매크로(탄,단,지)에 각 g당 칼로리를 곱한 것으로 계산합니다.<br/>
                  커스텀 음식을 입력할 때 참고 바랍니다.
                </v-card>

                <v-row dense>
                  <v-col md="6" cols="6" class="subtitle-2 big pa-2 ma-0">
                    <v-text-field
                      v-model="foodname"
                      class="center-input"
                      label="식품명"
                      placeholder="식품명"
                      required />
                  </v-col>


                  <v-col md="6" cols="6"  class="subtitle-2 big pa-2 ma-0">

                    <v-select
                      :items="food_types"
                      v-model.number="foodtype"
                      class="right-input"
                      label="식사 구분"
                      required />

                  </v-col>

                  <v-col md="3" cols="3"  class="subtitle-2 big pa-2 ma-0">

                    <v-text-field
                      dense
                      v-model.number="standard"
                      class="center-input mt-4"
                      label="기준량"
                      required
                      hint=""
                      persistent-hint />
                  </v-col>

                  <v-col md="3" cols="3"  class="subtitle-2 big pa-2 ma-0">

                    <v-select
                      :items="food_units"
                      v-model.number="foodunit"
                      class="right-input"
                      label="단위"
                      required />
                  </v-col>

                  <v-col md="2" cols="2"  class="subtitle-2 big pa-2 ma-0">

                    <v-text-field
                      dense
                      v-model.number="foodcarb"
                      class="center-input mt-4"
                      label="탄수화물"
                      required
                      hint="g"
                      persistent-hint />
                  </v-col>


                  <v-col md="2" cols="2"  class="subtitle-2 big pa-2 ma-0">

                    <v-text-field
                      dense
                      v-model.number="foodprotein"
                      class="center-input mt-4"
                      label="단백질"
                      required
                      hint="g"
                      persistent-hint />
                  </v-col>


                  <v-col md="2" cols="2"  class="subtitle-2 big pa-2 ma-0">

                    <v-text-field
                      dense
                      v-model.number="foodfat"
                      class="center-input mt-4"
                      label="지방"
                      required
                      hint="g"
                      persistent-hint />
                  </v-col>

                  <v-col cols="12"  class="subtitle-2 big pa-1 ma-0">
                    <v-btn color="#FBDE44FF"  @click="addCustomFood" block height="50">
                      추가
                    </v-btn>
                  </v-col>

                </v-row>
              </v-card>

              <v-card class="pa-4 ma-3 text-left" flat>

                <v-row dense>
                  <v-col md="12" cols="12">
                    <v-container fluid v-if="loading" class="ma-10">
                      <v-row dense>
                        <v-col cols="12">
                          <v-row class="align-center justify-center">
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                            <v-col cols="12" align=center>
                              커스텀 음식을 불러오고 있습니다.
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-row dense>
                      <v-col cols="12" class="title">
                        커스텀 음식 목록
                      </v-col>
                    </v-row>
                    <v-row dense >
                      <v-col md="3" cols="12" v-for="(each, i) in food_list" :key="i">
                        <v-card outlined class="elevation-0 pa-2 ma-0">
                          <v-row dense>
                            <v-col md="6" sm="5" class="subtitle-2">
                              {{ each.name }}
                              <v-tooltip slot="append" bottom  color="black" content-class="white--text" class="tooltip" v-if="each.subfoodlist !== undefined && each.subfoodlist.length>0">
                                <template v-slot:activator="{ on }">
                                  <v-icon color="grey" class="load" v-on="on">mdi-comment-question-outline</v-icon>
                                </template>
                                <div class="caption" v-for="(each2, i2) in each.subfoodlist" :key="i2">
                                  {{ each2.name }} <br/> - 탄: {{ each2.carb }}, 단: {{ each2.protein }}, 지: {{ each2.fat }}
                                </div>
                              </v-tooltip>
                            </v-col>
                            <v-col md="6" sm="7" align="right">
                              {{ ( each.type == 2 ) ? "보충제":"일반" }}
                              <v-btn xSmall color="#FBDE44FF" height="100%" class="elevation-0 op_btn" @click="editCustomFood(i, each)" >
                                <v-icon color="black" small>edit</v-icon>
                              </v-btn>
                              <v-btn xSmall color="#FBDE44FF" height="100%" class="elevation-0 op_btn" @click="deleteCustomFood(i, each._id)" >
                                <v-icon color="black" small>close</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="6" class="caption">{{ each.calory }}Kcal</v-col>
                            <v-col cols="6" class="caption" align="right">{{ each.standard ? each.standard : 100 }} {{each.unit ? each.unit: 'g'}}</v-col>
                            <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #70AD47">{{ each.carb }}g</v-col>
                            <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #4472C7">{{ each.protein }}g</v-col>
                            <v-col cols="4" align="right" class="caption" style="border-bottom: 1px solid #ED7D31">{{ each.fat }}g</v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>

                </v-row>

                <v-dialog
                  v-model="dialog_custom_food"
                  :fullscreen="mobile"
                  hide-overlay transition="fade-transition"
                  @click:outside="closeCustomFood">
                  <v-card class="pa-0 ma-0">

                    <v-toolbar dark color="#FBDE44FF" v-if="mobile" class="mb-5">
                      <v-btn icon color="black" @click="closeCustomFood">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title class="black--text">커스텀 음식 수정</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn color="black" text @click="confirmEditCustomFood">저장</v-btn>
                      </v-toolbar-items>
                    </v-toolbar>

                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12" class="title mb-0 font-weight-bold">
                          커스텀 음식 수정
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col md="6" cols="6" class="subtitle-2 big pa-2 ma-0">
                          <v-text-field
                            v-model="editItem_custom_food.name"
                            class="center-input"
                            label="식품명"
                            disabled
                            placeholder="식품명"
                            required />
                        </v-col>


                        <v-col md="6" cols="6"  class="subtitle-2 big pa-2 ma-0">

                          <v-select
                            :items="food_types"
                            v-model.number="editItem_custom_food.type"
                            class="right-input"
                            label="식사 구분"
                            required />

                        </v-col>

                        <v-col md="3" cols="3"  class="subtitle-2 big pa-2 ma-0">

                          <v-text-field
                            dense
                            v-model.number="editItem_custom_food.standard"
                            class="center-input mt-4"
                            label="기준량"
                            required
                            hint=""
                            persistent-hint />
                        </v-col>

                        <v-col md="3" cols="3"  class="subtitle-2 big pa-2 ma-0">

                          <v-select
                            :items="food_units"
                            v-model.number="editItem_custom_food.unit"
                            class="right-input"
                            label="단위"
                            required />
                        </v-col>

                        <v-col md="2" cols="2"  class="subtitle-2 big pa-2 ma-0">

                          <v-text-field
                            dense
                            v-model.number="editItem_custom_food.carb"
                            class="center-input mt-4"
                            label="탄수화물"
                            required
                            hint="g"
                            persistent-hint />
                        </v-col>


                        <v-col md="2" cols="2"  class="subtitle-2 big pa-2 ma-0">

                          <v-text-field
                            dense
                            v-model.number="editItem_custom_food.protein"
                            class="center-input mt-4"
                            label="단백질"
                            required
                            hint="g"
                            persistent-hint />
                        </v-col>


                        <v-col md="2" cols="2"  class="subtitle-2 big pa-2 ma-0">

                          <v-text-field
                            dense
                            v-model.number="editItem_custom_food.fat"
                            class="center-input mt-4"
                            label="지방"
                            required
                            hint="g"
                            persistent-hint />
                        </v-col>

                      </v-row>
                    </v-card-text>

                    <v-card-actions v-if="!mobile">
                      <v-btn color="secondary" class="white--text" @click="closeCustomFood">취소</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="#FBDE44FF" class="black--text" @click="confirmEditCustomFood">저장</v-btn>
                    </v-card-actions>


                  </v-card>
                </v-dialog>


          </v-card>
        </v-col>
      </v-row>

    </div>
    <div v-if="!userloading && !user.login" >
      <NeedLogin />
    </div>
    <div v-if="userloading">
      <UserLoading />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';
import {
  get_custom_activity,
  post_custom_activity,
  delete_custom_activity,
  get_custom_food,
  post_custom_food,
  delete_custom_food,
  edit_custom_food,
} from '../../common/functions.js';

import { mapGetters } from 'vuex';

import S3 from 'aws-sdk/clients/s3'
import AWS from 'aws-sdk/global';

const average = (array) => array.reduce((a, b) => a + b) / array.length;

const linest = function(y, x) {
  let linest = 0;

  if ( y.length == x.length ) {
    let avgY = average(y);
    let avgX = average(x);
    let dividend = [];
    let divisor = [];
    for ( let i = 0; i < y.length; i++ ) {
      dividend[i] = (x[i] - avgX) * (y[i] - avgY);
      divisor[i] = Math.pow((x[i] - avgX), 2);
    }
    linest = dividend.reduce((p,n) => (p+n)) / divisor.reduce((p,n) => (p+n))
  }
  return linest;
}

const calc_par = function(avgbpm, basebpm, wotime) {
  let l = 0;

  let a_ = linest([1, 2], [basebpm, Math.round(basebpm*1.6*10)/10]);
  let b_ = linest([2, 8], [Math.round(basebpm*1.6*10)/10, Math.round(basebpm*2.4*10)/10]);

  if ( (avgbpm / basebpm) > 1.6 ) {
    l = avgbpm*b_+-10
  } else {
    l = avgbpm*a_+-0.66667
  }

  let l2 = 0;
  if ( l >= 1.2 ) {
    if ( wotime > 1 ) {
      l2 = l*0.05*wotime;
    } else {
      l2 = 0;
    }
  } else {
    l2 = 0;
  }

  return l + l2;
}

export default {
  data: () => {
    return {
      tab: "settings",
      loading: true,
      basebpm: 65,
      avgbpm: 100,
      wotime: 1,
      dialog_custom_food: false,
      woname: "",
      foodname: "",
      foodkcal: 0,
      foodcarb: 0,
      foodprotein: 0,
      foodfat: 0,
      foodtype: 1,
      foodunit: 'g',
      activity: [],
      food_list: [],
      standard: '100',
      food_types: [
        { text: '일반', value: 1, },
        { text: '보충제', value: 2, },
      ],
      food_units: [
        { text: 'g', value: 'g', },
        { text: 'ml', value: 'ml', },
        { text: '스쿱', value: '스쿱', }
      ],
      valid: false,
      name: "",
      email: "",
      isgoogle: "",
      password: "",
      passRules: [
        v => v.length >= 8 || '최소 8글자를 입력하세요.',
      ],
      editItem_custom_food: {
        name: "",
        type: 1,
        carb: 0,
        protein: 0,
        fat: 0,
        unit: 'g',
        standard: 100,
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      data: "data",
      mobile: "mobile",
      userloading: "userloading",
      loaded: "loaded",

    }),
    getLoaded: function() {
      return this.$store.getters.loaded;
    },
  },
  watch: {
    getLoaded: async function(v, oldv) {
      if ( v == true ) {
        await this.reload_info();
      }
    },
  },
  mounted: async function() {
    if ( this.loaded ) {
      await this.reload_info();
    }
  },
  methods: {
    reload_info: async function() {
      if ( firebase.auth().currentUser ) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        const user = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/one', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        this.email = user.data.email;
        this.name = user.data.name;
        this.basebpm = user.data.bpm;

        const custom_activity = await get_custom_activity(user.data.email);
        if ( custom_activity.length > 0 ) {
          this.activity = custom_activity;
        }

        const custom_food = await get_custom_food(user.data.email, 0);
        console.log(custom_food);
        if ( custom_food.length > 0 ) {
          this.food_list = custom_food;
        }

        this.loading = false;
      } else {
        setTimeout(() => {
          this.reload_info();
        }, 500);
      }
    },
    deleteCustomActivity: async function(i, _id) {
      if ( !confirm('삭제하시겠습니까?') )
        return;

      this.activity.splice(i, 1);

      await delete_custom_activity(this.email, _id);
    },
    deleteCustomFood: async function(i, _id) {
      if ( !confirm('삭제하시겠습니까?') )
        return;

      this.food_list.splice(i, 1);

      await delete_custom_food(this.email, _id);
    },

    editCustomFood: async function(i, item) {
      this.dialog_custom_food = true;
      this.editItem_custom_food = {
        name: item.name,
        type: item.type,
        carb: item.carb,
        protein: item.protein,
        fat: item.fat,
        standard: item.standard,
        unit: item.unit
      }

    },

    confirmEditCustomFood: async function() {
      await edit_custom_food(this.email, this.editItem_custom_food);

      for ( let each of this.food_list ) {
        if ( each.name == this.editItem_custom_food.name ) {
          each.type = this.editItem_custom_food.type;
          each.carb = this.editItem_custom_food.carb;
          each.protein = this.editItem_custom_food.protein;
          each.fat = this.editItem_custom_food.fat;
          each.standard = this.editItem_custom_food.standard;
          each.unit = this.editItem_custom_food.unit;
        }
      }

      this.editItem_custom_food = {
        name: "",
        type: 1,
        carb: 0,
        protein: 0,
        fat: 0,
        standard: 100,
        unit: 'g',
      }
      this.dialog_custom_food = false;
    },

    addCustomActivity: async function() {

      if ( this.woname == "" ) {
        alert("운동 이름을 입력하세요");
        return
      }

      const par_ = Math.round(calc_par(this.avgbpm, this.basebpm, this.wotime)*100, 1)/100;

      this.activity.push({
        name: this.woname,
        time: this.wotime,
        par: par_,
      });

      await post_custom_activity(this.email, {
        name: this.woname,
        time: this.wotime,
        par: par_,
      });

      this.wotime = "";

    },
    addCustomFood: async function() {

      if ( this.foodname == "" ) {
        alert("음식명을 입력하세요");
        return
      }

      let food_ = {
        type: this.foodtype,
        name: this.foodname,
        calory: this.foodcarb*4+this.foodprotein*4+this.foodfat*9,
        carb: this.foodcarb,
        protein: this.foodprotein,
        fat: this.foodfat,
        standard: this.standard,
        unit: this.foodunit,
      }

      this.food_list.push(food_);

      await post_custom_food(this.email, food_);

    },
    changeBaseBPM: async function() {
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/bpm/', {
        email: this.email,
        bpm: this.basebpm,
      }, {
        headers: {'id_token': id_token},
      });

    },
    deleteaccount: async function() {
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.delete(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/', {
          data: {
            email: this.user.data.email,
          },
          headers: {'id_token': id_token},
      });
      const user = firebase.auth().currentUser;
      user.delete().then(function() {
        this.$router.push({ path:"/main" });
      }).catch(function(error) {
        console.log(error);
      });
    },

    closeCustomFood: function () {
      this.dialog_custom_food = false;
      // this.defaultItem_template.name = "";
      // this.template_selected = false;
    },
  }
}
</script>

<style scoped>
</style>