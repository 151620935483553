<template>
  <div class="pa-5 basic">
    오류 신고는 좀 더 편리한 신고를 위해 카페로 이전되었습니다.<br/>
    <v-btn text href="https://cafe.naver.com/hibernating/menu/58" target="_blank">
      오류 신고 바로가기
    </v-btn>
<!--
    <div v-if="!userloading && user.login" class="pa-5 basic">
      <v-row dense>
        <v-col cols="12" class="body-2">
          오류 신고는 좀 더 편리한 신고를 위해 카페로 이전되었습니다


          </div> 페이지 입니다.<br/>
          재현이 가능해야 오류 수정이 쉽기 때문에, 최대한 자세히 서술 부탁드립니다.<br/>
          여러분의 오류 신고 덕분에 운동 도우미가 발전하고 있습니다.<br/>
          <b>오류가 발생하는 창에서, 마우스 오른쪽 -> 검사 -> 콘솔 화면을 스크린샷 해주세요</b><br/>
          감사합니다.
        </v-col>
      </v-row>
      <v-row dense align="center" justify="center">
        <v-dialog
          v-model="dialog"
          :fullscreen="mobile"
          hide-overlay
          transition="fade-transition"
          @click:outside="close"
          style="overflow:auto;-webkit-overflow-scrolling:touch"
          width="800" >
          <template v-slot:activator="{ on }">
            <v-btn class="mx-3 ma-3 " color="black" fab v-on="on" elevation=0 :class="{mobile_input:mobile, desktop_input:!mobile}">
              <v-icon color="#FBDE44FF" x-large>add</v-icon>
            </v-btn>
          </template>
          <v-card class="pa-0 ma-0">
            <v-toolbar dark color="#FBDE44FF" v-if="mobile" class="mb-5">
              <v-btn icon color="black" @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title class="black--text">오류 신고</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn color="black" text @click="save(bug_id)">저장</v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-card-text>

              <v-row dense>

                <v-col cols="12">
                  <v-text-field
                    v-model="title"
                    placeholder="오류 요약" />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="info"
                    placeholder="브라우저, 기기 정보(e.g 크롬, 아이폰, 안드로이드, PC )" />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="description"
                    placeholder="오류 설명" />
                </v-col>
                <v-col cols="12" v-if="!is_edit">
                  <v-file-input
                    v-model="img_file"
                    label="이미지 추가"
                    @change="select_image"
                  >
                  </v-file-input>
                </v-col>
                <v-col cols="12" v-if="!is_edit">
                  <v-row dense v-for="(each, idx) in img_list" :key="idx">
                    <v-col cols="10">
                      {{ each.name }}
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        text
                        @click="delete_image(idx)">X</v-btn>

                    </v-col>
                  </v-row>
                </v-col>

              </v-row>

            </v-card-text>

            <v-card-actions v-if="!mobile">
              <v-btn color="secondary" class="white--text" @click="close">취소</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="#FBDE44FF" class="black--text" @click="save(bug_id)">저장</v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>

        <v-col cols="12" class="limited">
          <v-row dense class="pa-2">
            <v-col cols="12">
              <v-container fluid v-if="loading" class="ma-10">
                <v-row dense>
                  <v-col cols="12">
                    <v-row class="align-center justify-center">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>

          <v-card outlined v-for="each in bugs" :key="each._id" class="pa-3 mt-2 body-2">
            <v-row dense>
              <v-col cols="8">
                <v-row dense>
                  <v-col cols="6" class="font-weight-bold">
                    <span v-if="each.status == 0" class="grey--text">미확인</span>
                    <span v-if="each.status == 1" class="blue--text">진행중</span>
                    <span v-if="each.status == 2" class="red--text">완료</span>
                    {{ each.title }}
                  </v-col>
                  <v-col cols="3" class="caption text-right">
                    {{ each.date }}
                  </v-col>
                  <v-col cols="3" class="text-right">
                    <v-btn v-if="each.status == 0" small color="yellow" @click="open_bug(each)">수정</v-btn>
                    <v-btn v-if="each.status == 0" small color="red" @click="delete_bug(each._id, each.links.key)">삭제</v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-btn v-for="(img, idx) in each.links.link" :key="idx" text :href="img" xSmall target="_blank">
                      <v-icon>link</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="caption">
                    {{ each.info }}
                  </v-col>
                  <v-col cols="12">
                    {{ each.description }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <v-row dense>
                  <v-col cols="10">
                    <v-textarea
                      outlined
                      dense
                      solo
                      flat
                      rows="3"
                      class="elevation-0"
                      v-model="comment[each._id]" />
                  </v-col>

                  <v-col cols="2">
                    <v-btn small color="primary" @click="write_comment(each._id)">
                      작성
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <v-row dense v-for="each_comment in each.comment" :key="each_comment._id" class="caption">
                      {{ each_comment.date}} : {{ each_comment.comment }}
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

          </v-card>

        </v-col>
      </v-row>

    </div>
    <div v-if="!userloading && !user.login" >
      <NeedLogin />
    </div>
    <div v-if="userloading">
      <UserLoading />
    </div> -->
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import { mapGetters } from 'vuex';

import S3 from 'aws-sdk/clients/s3'
import AWS from 'aws-sdk/global';

export default {
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters({
      user: "user",
      mobile: "mobile",
      data: "data",
      userloading: "userloading",

    }),
  },
  data: () => {
    return {
      is_edit: false,
      bugs: [],
      comment: {},
      loading: true,
      dialog: false,
      img_file: null,
      img_list: [],
      bug_id: "",
      title: "",
      info: "",
      description: "",
      // ---- env 파일에 숨길 내용 -----
      albumBucketName: 'hibernating-image',
      bucketRegion: 'ap-northeast-2',
      IdentityPoolId: 'ap-northeast-2:fabc642c-21b5-4d9d-98d8-2376bfec4c71',
      albumName: 'test-album'
      // -------------------------------
    }
  },
  mounted: async function() {
    this.$nextTick(async function() {
      await this.reload_info();
    });
  },
  methods: {
    reload_info: async function() {
      this.bugs = [];
      if ( firebase.auth().currentUser ) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        const bugs_ = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/bug/', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        for ( const each of bugs_.data ) {
          let t_ = each;
          t_.links = JSON.parse(each.links);
          t_.comment = [];
          this.bugs.push(t_);
        }

        this.loading = false;

        await this.reload_comment();
      } else {
        setTimeout(() => {
          this.reload_info();
        }, 500);
      }
    },
    reload_comment: async function() {
      if ( firebase.auth().currentUser ) {

        let bugs_ = [];
        for ( const each_bug of this.bugs ) {
          bugs_.push(each_bug._id);
        }
        const id_token = await firebase.auth().currentUser.getIdToken();
        const comments_ = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/bug/comment/', {
          params: {
            email: this.user.data.email,
            bugs: bugs_,
          },
          headers: {'id_token': id_token},
        });

        for ( const each_bug of this.bugs ) {
          each_bug.comment = comments_.data[each_bug._id];
        }

      } else {
        setTimeout(() => {
          this.reload_comment();
        }, 500);
      }

    },
    connect_s3() {
      AWS.config.update({
        region: this.bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: this.IdentityPoolId
        })
      })
      const s3 = new S3({
        apiVersion: "2006-03-01",
        params: { Bucket: this.albumBucketName }
      })
      return s3
    },
    select_image: async function () {
      this.img_list.push(new File([this.img_file], this.$moment().format("YYYY-MM-DD-hh-mm-ss") + "_" +this.img_file.name));
      setTimeout(()=>{
        this.img_file = null;
      },300);
    },
    close: async function() {
      this.dialog = false;
      this.is_edit = false;
    },
    save: async function(id) {

      if ( !this.is_edit ) {

        let link_list = [];
        let stop = false;
        let uploaded = [];

        const s3 = this.connect_s3();

        let albumPhotosKey = encodeURIComponent(this.user.data.email) + "/"
        for (let each in this.img_list ) {
          let photoKey = albumPhotosKey + this.img_list[each].name;
          let upload = new S3.ManagedUpload({
            params: {
              Bucket: this.albumBucketName,
              Key: photoKey,
              Body: this.img_list[each],
              ACL: 'public-read'
            }
          })
          try {
            let r = await upload.promise();
            link_list.push(r.Location);
            uploaded.push(photoKey);
          } catch(e) {
            stop = true;
            console.log(e);
          }
        }

        if ( !stop ) {
          const id_token = await firebase.auth().currentUser.getIdToken();
          await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/bug/', {
            email: this.user.data.email,
            title: this.title,
            info: this.info,
            description: this.description,
            links: JSON.stringify({ link: link_list, key: uploaded }),
          }, {
            headers: {'id_token': id_token},
          });

        } else {
          // 오류가 발생한 경우
          for ( let each in uploaded ) {
            try {
              let r = await s3.deleteObject({Key:each});
            } catch(e) {
              console.log(e);
            }
          }
        }
      } else {
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.put(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/bug/', {
          email: this.user.data.email,
          id: this.bug_id,
          title: this.title,
          info: this.info,
          description: this.description,
        }, {
          headers: {'id_token': id_token},
        });

        this.is_edit = false;
      }

      this.reload_info();

      this.title = "";
      this.info = "";
      this.description = "";
      this.img_file = null;
      this.img_list = [];

      this.dialog = false;

    },
    delete_bug: async function(_id, key) {
      if ( confirm("정말 삭제하시겠습니까?") ) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.delete(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/bug/', {
            data: {
              email: this.user.data.email,
              id: _id,
            },
            headers: {'id_token': id_token},
        });

        const s3 = this.connect_s3()

        for ( let each of key ) {
          try {
            let r = await s3.deleteObject({Key: each}).promise();
          } catch(e) {
            console.log(e);
          }
        }

        this.reload_info();

      }

    },
    open_bug: async function(item) {
      this.is_edit = true;
      this.dialog = true;
      this.bug_id = item._id;
      this.title = item.title;
      this.info = item.info;
      this.description = item.description;
    },
    write_comment: async function(_id) {
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/bug/comment/', {
        email: this.user.data.email,
        bug: _id,
        comment: this.comment[_id],
      }, {
        headers: {'id_token': id_token},
      });

      for ( let each_bug of this.bugs ) {
        if ( each_bug._id == _id ) {
          let t_ = {
            date: this.$moment().format("YYYY-MM-DD"),
            comment: this.comment[_id],
          };
          if ( each_bug.comment === undefined ) {
            each_bug.comment = [];
            each_bug.comment.push(t_);
          } else {
            each_bug.comment.unshift(t_);
          }
        }
        console.log(each_bug);
      }

      this.comment[_id] = "";

    }
  }
}
</script>

<style scoped>

.theme--dark.v-divider {
    border-color: #FBDE44FF !important;
}

.op_btn {
  position:absolute;
  right:0;
  top:0;
}

.op_btn::before {
  color: transparent;
}

.op_btn::hover {
  color: transparent;
}

</style>
