<template>
  <div>
    <div v-if="!userloading && user.login" class="text-right pt-2 basic">

      <v-row no-gutters align="center" justify="center" >

        <v-col cols="12">

          <v-card flat class="pa-4 ma-3">
            <v-row dense class="pa-2">
              <v-col cols="12">
                <v-container fluid v-if="loading" class="ma-10">
                  <v-row dense>
                    <v-col cols="12">
                      <v-row class="align-center justify-center">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
                <v-data-table
                  v-else
                  :headers="headers"
                  :items="desserts"
                  sort-by="push_date"
                  flat
                  id="purchase_info_table"
                  no-data-text="목록이 없습니다."
                  :footer-props="{
                    showFirstLastPage: true,
                    itemsPerPageText: ''
                  }"  >
                  <template v-slot:top>
                    <v-dialog v-model="Img_dialog" max-width="400px" @keydown.esc="close_img_dialog()">
                      <v-card >

                        <v-divider />

                        <v-card-text class="pt-3">
                          <v-img :aspect-ratio="1.35" :src="img_src" class="grey lighten-2" contain>
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="#FBDE44FF" @click="close_img_dialog()"> 닫기 </v-btn>
                        </v-card-actions>

                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:item.screenshot="{item}">
                    <v-row align="center">
                      <v-btn v-if="item.screenshot_link1" color="#FBDE44FF" class="ma-1"  @click="showImg($event, item, 1)">보기</v-btn>
                      <v-btn v-if="item.screenshot_link2" color="#FBDE44FF" class="ma-0 pa-0"  @click="showImg($event, item, 2)">보기</v-btn>
                    </v-row>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
                    <v-icon small @click="deleteItem(item)">delete</v-icon>
                  </template>
                  <template v-slot:item.admin_check="{ item }">
                    <v-icon class="ml-4" v-if="item.admin_check==1" right>mdi-check-bold</v-icon>
                    <v-icon class="ml-4" v-if="item.admin_check==0" right>mdi-timer</v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

    </div>
    <div v-if="!userloading && !user.login" >
      <NeedLogin />
    </div>
    <div v-if="userloading">
      <UserLoading />
    </div>

    <v-dialog v-model="dialog" :fullscreen="mobile" hide-overlay transition="fade-transition" @click:outside="close" >
      <template v-slot:activator="{ on }">
        <v-btn class="mx-3 ma-3" color="black" fab v-on="on" elevation=0  :class="{mobile_input:mobile, desktop_input:!mobile}">
          <v-icon color="#FBDE44FF" x-large>add</v-icon>
        </v-btn>
      </template>
      <v-card class="pa-0 ma-0 ">

        <v-toolbar dark color="#FBDE44FF" v-if="mobile" class="mb-5">
          <v-btn icon color="black" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="black--text">인증 추가</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="black" text @click="save">저장</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-row dense>
            <v-col cols="12" class="text-center mt-5">
              <v-combobox v-model="editedItem.orderShop" :items="shop_list" chips label="구매샵" height="32px" @change="changeRoute">
                <template v-slot:selection="data">
                  {{ data.item }}
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" class="text-center" >
              <v-text-field v-model="editedItem.orderId" label="주문번호"></v-text-field>
            </v-col>
            <v-col cols="9">
              <v-file-input
                v-model="img_file1"
                label="이미지 선택"
                @change="change_img(0)"
              >
              </v-file-input>
            </v-col>
            <v-col cols="3">
              <v-btn class="ma-2" left color="#FBDE44FF" @click="upload_img_to_s3">업로드</v-btn>
            </v-col>
            <v-col cols="12" v-if="editedItem.orderShop === '아이허브' | formTitle === '추가' && editedItem.orderShop != '마이프로틴'">
              <v-file-input
                v-model="img_file2"
                label="이미지 선택"
                @change="change_img(1)"
              >
              </v-file-input>
            </v-col>
          </v-row>
        </v-card-text>


        <v-card-actions v-if="!mobile">
          <v-spacer></v-spacer>
          <v-btn color="#FBDE44FF" class="black--text" @click="save" :disabled="check_upload == false" >저장</v-btn>
        </v-card-actions>


      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios'
import { mapGetters } from 'vuex'

import S3 from 'aws-sdk/clients/s3'
import AWS from 'aws-sdk/global';

import api_conf from '../../config/config.json';

import NeedLogin from './NeedLogin';
import UserLoading from './UserLoading';

export default {
  components: {
    NeedLogin,
    UserLoading,
  },
  props: {
    msg: String
  },
  data: function() {
    return{
      loading: true,
      dialog: false,
      Img_dialog: false,
      img_src: '',
      img_file1: null,
      img_file2: null,
      check_upload: false,
      temp_file_list: [],
      shop_list: [
        '마이프로틴',
        '아이허브',
      ],
      headers: [
        { text: '주문번호', value: 'orderId' },
        { text: '주문샵', value: 'orderShop' },
        { text: '스크린샷', value: 'screenshot', sortable: false },
        { text: '날짜', value: 'date' },
        { value: 'action', sortable: false },
        { text: '관리자 확인', value: 'admin_check'}
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        orderShop: '',
        orderId: '',
        screenshot_link1: '',
        screenshot_link2: '',
        screenshot_name1: '',
        screenshot_name2: '',
        admin_check: 0
      },
      defaultItem: {
        orderShop: '',
        orderId: '',
        screenshot_link1: '',
        screenshot_link2: '',
        screenshot_name1: '',
        screenshot_name2: '',
        admin_check: 0
      },
      // ---- env 파일에 숨길 내용 -----
      albumBucketName: 'hibernating-image',
      bucketRegion: 'ap-northeast-2',
      IdentityPoolId: 'ap-northeast-2:fabc642c-21b5-4d9d-98d8-2376bfec4c71',
      albumName: 'test-album'
      // -------------------------------
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      mobile: "mobile",
      userloading: "userloading",


    }),
    formTitle () {
      return this.editedIndex === -1 ? '추가' : '수정'
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  created () {
   // this.initialize()
  },
  mounted: async function() {
    this.$nextTick(async function() {
      await this.reload_info();
    });
  },
  methods: {
    reload_info: async function() {
      if ( firebase.auth().currentUser ) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        const purchaseinfo = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/purchaseinfo', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        if( purchaseinfo.data.length > 0 ) {
          this.desserts = purchaseinfo.data
        }
        this.loading = false;
      } else {
        setTimeout(() => {
          this.reload_info();
        }, 500);
      }

    },
    initialize () {
    },
    connect_s3() {
      AWS.config.update({
        region: this.bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: this.IdentityPoolId
        })
      })
      const s3 = new S3({
        apiVersion: "2006-03-01",
        params: { Bucket: this.albumBucketName }
      })
      return s3
    },
    check_valid () {
      let r = { message: '', value: 0 }
      if ( this.editedItem.orderShop == '' ) {
        r.message = '구매샵을 선택해주십시오.'
        r.value = 1
      }
      if ( this.editedItem.orderId == '' ) {
        r.message = '주문번호를 입력해주십시오.'
        r.value = 1
      }
      for ( let each_item in this.desserts ) {
        if ( this.editedIndex < 0 && this.editedItem.orderId == this.desserts[each_item].orderId ) {
          r.message = "주문번호가 이미 존재합니다. 다시 입력해주십시오. "
          r.value = 1
        }
      }
      return r
    },
    get_img_from_s3 () {
      const s3 = this.connect_s3()
      s3.listObjects({
        Delimiter: "/"
      }, (err, data) => {
        if (err ) {
          return alert('There was an error listing your albums: ' + err.message)
        } else {
          console.log(data)
        }
      })
    },
    upload_img_to_s3 () {
      let r = this.check_valid()
      if ( r.value ) {
        return alert(r.message)
      }

      if ( !this.img_file1 ) {
        return alert('Please choose a file to upload first')
      } else if ( this.editedItem.orderShop == '아이허브' && !this.img_file2 ) {
        return alert('Please choose two files to upload first')
      }
      const s3 = this.connect_s3()
      let albumPhotosKey = encodeURIComponent(this.user.data.email) + "/"
      for (let each in this.temp_file_list ) {
        let photoKey = albumPhotosKey + this.temp_file_list[each].name
        let upload = new S3.ManagedUpload({
          params: {
            Bucket: this.albumBucketName,
            Key: photoKey,
            Body: this.temp_file_list[each],
            ACL: 'public-read'
          }
        })
        let promise = upload.promise()
        promise.then((data) => {
          if ( each == 0 ) {
            this.editedItem.screenshot_link1 = data.Location
            this.editedItem.screenshot_name1 = this.temp_file_list[each].name
          } else if ( each == 1 ) {
            this.editedItem.screenshot_link2 = data.Location
            this.editedItem.screenshot_name2 = this.temp_file_list[each].name
          }
        }, (err) => {
          console.log(err)
          return alert("There was an error uploading your photo: ", err.message);
        })
      }
      this.check_upload = true
    },
    delete_img_from_s3 () {
      let file_name_list = []
      file_name_list.push(this.editedItem.screenshot_name1)
      if ( this.editedItem.screenshot_name2 )
        file_name_list.push(this.editedItem.screenshot_name2)

      let albumName = encodeURIComponent(this.user.data.email) + "/"
      const s3 = this.connect_s3()
      for ( let each in file_name_list ) {
        let photoKey = albumName + file_name_list[each]
        s3.deleteObject({Key:photoKey}, (err, data) => {
          if ( err ) {
            console.log(err)
            return alert("There was an error deleting your folder: ", err.message);
          }
        })
      }
    },
    changeRoute() {
      this.img_file2 = null
      this.check_upload = false
    },
    showImg (event, item, link_num) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if ( link_num === 1 ) {
        this.img_src = this.editedItem.screenshot_link1
      } else if ( link_num === 2 ) {
        this.img_src = this.editedItem.screenshot_link2
      }
      this.Img_dialog = true
    },
    change_img: function(idx) {
      if ( this.img_file1 != undefined && idx == 0 ){
        this.temp_file_list[idx] = this.img_file1
      }
      if ( this.img_file2 != undefined && idx == 1 ) {
        this.temp_file_list[idx] = this.img_file2
      }
      let file_name_list =
      this.editedItem.screenshot_link1 = ''
      this.editedItem.screenshot_name1 = ''
      this.editedItem.screenshot_link2 = ''
      this.editedItem.screenshot_name2 = ''
      this.check_upload = false
    },
    close_img_dialog() {
      this.close(event)
    },
    editItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.check_upload = true
    },
    async deleteItem (item) {
      const index = this.desserts.indexOf(item)
      const deleteinfo = this.desserts[index]
      let file_name_list = []
      file_name_list.push(deleteinfo.screenshot_name1)
      if ( deleteinfo.screenshot_name2 )
        file_name_list.push(deleteinfo.screenshot_name2)

      if ( confirm('정말 삭제하시겠습니까?') ) {
        this.delete_img_from_s3(file_name_list);
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/purchaseinfo/del', {
          email: this.user.data.email,
          info : deleteinfo
        }, {
          headers: {'id_token': id_token},
        })
        this.desserts.splice(index, 1)
      }
    },
    close (event) {
      this.img_file1 = null
      this.img_file2 = null
      this.check_upload = false
      this.dialog = false
      this.Img_dialog = false

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    async save () {
      let r = this.check_valid()
      if ( r.value ) {
        return alert(r.message)
      }
      let orderId_old = ''
      if (this.editedIndex > -1) {
        orderId_old = this.desserts[this.editedIndex].orderId
        if ( this.editedItem.orderShop == '마이프로틴' ) {
          this.editedItem.screenshot_link2 = ''
          this.editedItem.screenshot_name2 = ''
        }
        Object.assign(this.desserts[this.editedIndex], this.editedItem)
      } else {
        orderId_old = this.editedItem.orderId
        this.editedItem.date = this.$moment().format("YYYY-MM-DD")
        this.desserts.push(this.editedItem)
        this.img_file1 = null
        this.img_file2 = null
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/purchaseinfo', {
        email: this.user.data.email,
        orderId_old: orderId_old,
        info : this.editedItem
      }, {
        headers: {'id_token': id_token},
      })

      this.close()
    },
  },
}
</script>

<style>

#readonly_orderId {
  text-align: right;
}

</style>
