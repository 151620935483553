<!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
<template>
  <div>
    <div v-if="user.login" class="pt-2 basic">
      <v-row no-gutters align="center" justify="center" >
        <v-col cols="12">
          <v-card class="pa-4 ma-3 text-left" flat>
            <v-row dense>
              <v-col cols="3" >

                <v-row dense>
                  <v-col cols="12" class="title mb-5 font-weight-bold">
                    펀딩 요청 입력<br/>
                    <span class="body-2">
                      377-123630-01-016 <br/> 기업은행 / 겨울잠
                    </span><br/>
                  </v-col>
                </v-row>

              </v-col>
              <v-col cols="9">

                <v-row dense>
                  <v-col cols="6">
                    <v-row dense>
                      <v-col md="12" cols="12">
                        <v-date-picker
                          v-model="date"
                          color="grey lighten-2"
                          locale="ko-kr"
                          :day-format="dayformat"
                          full-width
                        ></v-date-picker>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row dense>
                      <v-col md="6" cols="6" class="subtitle-2 big2 pa-2 ma-0">
                        <v-text-field
                          dense
                          v-model="name"
                          class="center-input"
                          label="예금주"
                          placeholder="예금주를 입력하세요"
                          required />
                      </v-col>

                      <v-col md="6" cols="6"  class="subtitle-2 big2 pa-2 ma-0">

                        <v-text-field
                          dense
                          v-model.number="amount"
                          class="center-input"
                          label="금액"
                          required
                          hint="원"
                          type="number"
                          persistent-hint />
                      </v-col>

                      <v-col md="6" cols="6" class="subtitle-2 big2 pa-2 ma-0">
                        <v-text-field
                          dense
                          v-model="rname"
                          class="center-input"
                          label="성함"
                          required />
                      </v-col>

                      <v-col md="6" cols="6" class="subtitle-2 big2 pa-2 ma-0">
                        <v-text-field
                          dense
                          v-model="phone"
                          class="center-input"
                          label="연락처"
                          required />
                      </v-col>

                      <v-col md="12" cols="12" class="subtitle-2 big2 pa-2 ma-0">
                        <v-select
                          dense
                          v-model="lecture"
                          class="center-input"
                          :items="lecture_list"
                          item-text="text"
                          item-value="value"
                          label="강의권 신청(5만원 일반인, 30만원 전문가)"
                          required />
                      </v-col>

                      <v-col md="12" cols="12" class="subtitle-2 big2 pa-2 ma-0">
                        <v-select
                          v-if="lecture !== 0"
                          dense
                          v-model="lecture_address"
                          class="center-input"
                          :items="location_list"
                          label="강의권 신청시 주소"
                          required />
                      </v-col>

                      <v-col md="12" cols="12" class="subtitle-2 big2 pa-2 ma-0">
                        <v-select
                          v-if="lecture == 30 && consult_count < 10"
                          dense
                          v-model="consult"
                          class="center-input"
                          :items="consult_list"
                          item-text="text"
                          item-value="value"
                          label="개인 영양 컨설팅(선착순)"
                          required />
                        <!-- <span v-if="consult_count >= 10">
                          선착순 컨설팅 참여인원: {{consult_count}} 마감
                        </span>
                        <span v-if="consult_count < 10 && consult_count >= 0">
                          선착순 컨설팅 참여인원: {{consult_count}}
                        </span> -->
                      </v-col>

                      <v-col cols="12"  class="subtitle-2 big2 pa-1 ma-0">
                        <v-btn
                          color="#FBDE44FF"
                          class="elevation-0"
                          @click="addFundRequest"
                          block height="100px">
                          추가
                        </v-btn>
                      </v-col>

                    </v-row>

                  </v-col>

                </v-row>

              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters align="center" justify="center" >
        <v-col cols="12">
          <v-card class="pa-4 ma-3 text-left" flat>
            <v-row dense>
              <v-col cols="3" >

                <v-row dense>
                  <v-col cols="12" class="title mb-5 font-weight-bold">
                    내 펀딩 요청 목록<br/>
                    <span class="body-2"></span><br/>
                  </v-col>
                </v-row>

              </v-col>
              <v-col cols="9">

                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          예금주
                        </th>
                        <th class="text-left">
                          금액
                        </th>
                        <th class="text-left">
                          날짜
                        </th>
                        <th class="text-left">
                          성함
                        </th>
                        <th class="text-left">
                          연락처
                        </th>
                        <th class="text-left">
                          강의권 신청
                        </th>
                        <th class="text-left">
                          강의권 주소
                        </th>
                        <th class="text-left">
                          컨설팅 신청
                        </th>
                        <th class="text-left">
                          삭제
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in fund_request_list"
                        v-if="item.confirm != true"
                        :key="item.name"
                      >
                        <td>{{ item.name }}</td>
                        <td>{{ item.amount }}원</td>
                        <td>{{ item.date }}</td>
                        <td>{{ item.rname }}</td>
                        <td>{{ item.phone }}</td>
                        <td>{{ item.lecture }}</td>
                        <td>{{ item.lecture_address }}</td>
                        <td>{{ item.consult }}</td>

                        <td>
                          <v-btn
                            @click="del(item)"
                            >
                            삭제
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters align="center" justify="center" >
        <v-col cols="12">
          <v-card class="pa-4 ma-3 text-left" flat>
            <v-row dense>
              <v-col cols="3" >

                <v-row dense>
                  <v-col cols="12" class="title mb-5 font-weight-bold">
                    내 펀딩 목록<br/>
                    <span class="body-2"></span><br/>
                  </v-col>
                </v-row>

              </v-col>
              <v-col cols="9">

                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          예금주
                        </th>
                        <th class="text-left">
                          금액
                        </th>
                        <th class="text-left">
                          날짜
                        </th>
                        <th class="text-left">
                          성함
                        </th>
                        <th class="text-left">
                          연락처
                        </th>
                        <th class="text-left">
                          강의권 신청
                        </th>
                        <th class="text-left">
                          강의권 주소
                        </th>
                        <th class="text-left">
                          컨설팅 신청
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in fund_list"
                        :key="item.name"
                      >
                        <td>{{ item.name }}</td>
                        <td>{{ item.amount }}원</td>
                        <td>{{ item.date }}</td>
                        <td>{{ item.rname }}</td>
                        <td>{{ item.phone }}</td>
                        <td>{{ item.lecture }}</td>
                        <td>{{ item.lecture_address }}</td>
                        <td>{{ item.consult }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

              </v-col>
            </v-row>
          </v-card>
          <v-card class="pa-4 ma-3 text-center" flat>
            <img src="https://wintersleepingfunddesc.s3.ap-northeast-2.amazonaws.com/instruction.png" />
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios'
import { mapGetters } from 'vuex'

import api_conf from '../../config/config.json';


import moment from 'moment';
require('moment-timezone');


// import NeedLogin from './NeedLogin';
// import UserLoading from './UserLoading';

export default {
  components: {

  },
  props: {
    msg: String
  },
  data: function() {
    return {
      fund_list :[],
      fund_request_list : [],
      name:"",
      amount:0,
      rname:"",
      phone:"",
      lecture:0,
      lecture_address:"",
      consult:false,
      consult_count: -1,
      lecture_list: [
        {text:'없음', value:0},
        {text:'5만원 일반인', value:5},
        {text:'30만원 전문가', value:30}
      ],
      location_list: [
        "서울",
        "부산",
        "대구",
        "인천",
        "광주",
        "대전",
        "울산",
        "세종",
        "경기북부",
        "경기남부",
        "강원도",
        "충북",
        "충남",
        "전북",
        "전남",
        "경북",
        "경남",
        "제주"
      ],
      consult_list: [
        {text:'신청', value:true},
        {text:'안함', value:false},
      ],
      date:moment().tz('Asia/Seoul').format("YYYY-MM-DD"),
    }
  },
  computed: {
    ...mapGetters({
      user: "user",


    }),
    formTitle () {
      return this.editedIndex === -1 ? '추가' : '수정'
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    lecture_address (val) {
      this.load_count_by_location(val);
    }
  },
  created () {
   // this.initialize()
  },
  mounted: async function() {
    this.$nextTick(async function() {
      await this.reload_info();
    });
  },
  methods: {
    dayformat: function(date) {
      return Number(moment(date, "YYYY-MM-DD").format("DD"))
    },
    load_count_by_location: async function(location) {
      const id_token = await firebase.auth().currentUser.getIdToken();
      const count_by_location_request = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/fund/request/count_by_location', {
        params: { email: this.user.data.email, location: location },
        headers: {'id_token': id_token},
      });

      this.consult_count = count_by_location_request.data
    },
    reload_info: async function() {
      if ( firebase.auth().currentUser ) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        const fund_list = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/fund', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        if( fund_list.data.length > 0 ) {
          this.fund_list = fund_list.data
        }

        const fund_request_list = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/fund/request', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        if( fund_request_list.data.length > 0 ) {
          this.fund_request_list = fund_request_list.data
        }

      } else {
        setTimeout(() => {
          this.reload_info();
        }, 500);
      }

    },
    addFundRequest: async function(v) {
      if ( this.name == "" ) {
        alert("예금주를 입력하세요.")
        return
      }
      if ( this.amount == 0 ) {
        alert("금액을 입력하세요.")
        return
      }
      if ( this.date == "" ) {
        alert("날짜를 선택하세요")
        return
      }
      if ( confirm("펀드 요청을 하시겠습니까? 입금 후 작성 부탁드립니다.")) {
        const id_token = await firebase.auth().currentUser.getIdToken();

        const count_by_location_request = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/fund/request/count_by_location', {
          params: { email: this.user.data.email, location: location },
          headers: {'id_token': id_token},
        });

        if( count_by_location_request.data < 10 || this.consult == false) {

          const r = await axios.post(
            api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/fund/request/', {
            email: this.user.data.email,
            amount: this.amount,
            name: this.name,
            rname: this.rname,
            phone: this.phone,
            lecture: this.lecture,
            lecture_address: this.lecture_address,
            consult: this.consult,
            date: this.date,
          }, {
            headers: {'id_token': id_token},
          });

          this.clear_input();

          this.reload_info();
        } else {
          alert("해당 지역선착순 마감되었습니다.")
        }
      }
    },
    clear_input: function() {
      this.amount = 0;
      this.name = "";
      this.rname = "";
      this.phone = "",
      this.lecture = 0;
      this.lecture_address = "";
      this.consult = false;
    },
    del: async function(item) {
      if(confirm("삭제하시겠습니까?")) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        await axios.post(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/fund/request/delete/', {
          email: this.user.data.email,
          fund_request_id: item._id,
        }, {
          headers: {'id_token': id_token},
        });
        this.reload_info();
      }
    }
  },
}
</script>

<style>

#readonly_orderId {
  text-align: right;
}

</style>
